
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import html2pdf from 'html2pdf.js';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';
import LoftyCartCarousel from '../../components/loftycartcarousel';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnearticlescollection } from '../../api/articlescollection.api'

import './infopage.scss';






const Pinfopage = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyendpoint_6453e037e474ae33c68ff818, setLoftyEndpoint_6453e037e474ae33c68ff818] = useState({});
	
useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_6453e037e474ae33c68ff818 = await getOnearticlescollection({ _id: match.params.articleid, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_6453e037e474ae33c68ff818(loftyres_6453e037e474ae33c68ff818.data);
		}
		loftyHandleData();
	}, []);



  
  
  
  

  
  return (
    <div id="id6453d162a52ebcacc7a7ec33" >

      <div id="iu93" className="">
      
      <div id="i9t9" className="">
      
      <img
        className=""
        id="iv25"
        src="https://assetsprojects.s3.amazonaws.com/406raciltou54t5.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ik3f" className="">
      
        <div id="i4wgg" className="">
        
        <div className="" id="iwt3u">
          
          <div className="" id="ireak">
            {loftyendpoint_6453e037e474ae33c68ff818?.titlearticle}
          </div>
          
        </div>
        
        <div className="" id="i7wai">
          
          <LoftyEditorJsReadOnly id="undefined" className="" defaultValue={loftyendpoint_6453e037e474ae33c68ff818.infoarticledata} />
          
        </div>
        
        </div>
        
      </div>
      
      <div id="i71w" className="">
      
      <div id="ipm36" className="">
      
      <p className="" id="i81dl">
        <span>Siguenos en</span>
      </p>
      
        <a
          target=""
          className=""
          id="i6cxc"
          href="https://www.facebook.com/profile.php?id=100089754458312"
        >
          
      <img
        className=""
        id="ijrwk"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcclub.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i4oh9"
          href="https://www.instagram.com/mismemoriasqr/"
        >
          
      <img
        className=""
        id="iprxd"
        src="https://assetsprojects.s3.amazonaws.com/404mwbilexcehfe.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iz9xr"
          href="https://api.whatsapp.com/send/?phone=50489196588"
        >
          
      <img
        className=""
        id="i5n3w"
        src="https://assetsprojects.s3.amazonaws.com/406raciltoud35y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pinfopage);
  